<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">NEW COMAPANY INVENTORY</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form2" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md3>
            <v-select
              v-model="category_id"
              class="mx-2"
              dense
              outlined
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3 v-if="data_items.length>0">
            <v-select
              v-model="data_id"
              class="mx-2"
              dense
              outlined
              label="Data"
              :items="data_items"
              item-value="id"
              item-text="particulars"
              :rules="rules.combobox_rule"
              @change="selected_data"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3 v-if="particulars_items.length>0">
            <v-select
              v-model="particulars_id"
              class="mx-2"
              dense
              outlined
              label="Particulars"
              :items="particulars_items"
              item-value="particulars"
              item-text="particulars"
              :rules="rules.combobox_rule"
              @change="selected_particulars"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3 v-if="specify_items.length>0">
            <v-select
              v-model="specify_id"
              class="mx-2"
              dense
              outlined
              label="Specify"
              :items="specify_items"
              item-value="id"
              item-text="code"
              :rules="rules.combobox_rule"
              @change="selected_specify"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12 v-if="component_items.length>0">
            <v-select
              v-model="component_id"
              class="mx-2"
              dense
              outlined
              label="Components"
              :items="component_items"
              item-value="id"
              item-text="particulars"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="save_request"
              v-if="!saving_data"
            >
              Save Changes
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-layout>
      </v-form>
      <v-simple-table dense class="mt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-uppercase">
              No.
            </th>
            <th class="text-uppercase">
              Data
            </th>
            <th class="text-uppercase">
              Particulars
            </th>
            <th class="text-uppercase">
              Details
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in active_items"
            :key="item.id"
          >
            <td>
              {{ (active_items
              .map(function (x) {
              return x.id
              })
              .indexOf(item.id)+1) }}
            </td>
            <td>
              {{ item.code+'#'+item.no }}
            </td>
            <td>
              {{ (item.car_brand!=null?item.car_brand:'')+
              (item.equipment_details!=null?item.equipment_details:'') }}
            </td>
            <td>
              {{
              (item.equipment_code!=null?item.equipment_code+
              (item.equipment_components!=null?' ('+item.equipment_components+')':'')
              :'') }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving_data: false,
      alert: false,
      alert_message: '',

      category_id: '',
      data_id: '',
      particulars_id: '',
      specify_id: '',
      component_id: '',
      category_items: [],
      particulars_items: [],
      specify_items: [],
      component_items: [],
      data_items: [],
      active_items: [],
      deactive_items: [],
      particulars: '',
      is_minor: 0,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('request_particulars', ['initialize_new_particulars']),
      ...mapActions('company_inventory', ['initialize_company_inventory', 'list_of_category_inventory', 'list_of_inventory_specify', 'register_company_inventory']),
      initialize_data() {
        this.initialize_company_inventory()
          .then(response => {
            this.category_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_category(value) {
        this.active_items = []
        this.data_items = []
        this.data_id = ''
        this.particulars_items = []
        this.particulars_id = ''
        this.specify_id = ''
        this.component_id = ''
        this.specify_items = []
        this.component_items = []
        this.is_minor = 0
        this.list_of_category_inventory({
          category_id: value,
        })
          .then(response => {
            this.data_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_data(value) {
        this.particulars_items = []
        this.particulars_id = ''
        this.specify_id = ''
        this.component_id = ''
        this.specify_items = []
        this.component_items = []
        this.is_minor = 0
        var index = this.data_items
          .map(function (x) {
            return x.id
          })
          .indexOf(value);
        if (index != -1) {
          this.active_items = this.data_items[index].company_inventory
          this.particulars_items = this.data_items[index].inventory_particulars
        }
      },
      selected_particulars(value) {
        this.component_id = ''
        this.component_items = []
        this.is_minor = 0
        this.list_of_inventory_specify({
          data: this.data_items[this.data_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.data_id)].particulars,
          particulars: value,
        })
          .then(response => {
            this.specify_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_specify(value) {
        var index = this.specify_items
          .map(function (x) {
            return x.id
          })
          .indexOf(value);
        if (index != -1) {
          this.is_minor = this.specify_items[index].status === 'MINOR' ? 1 : 0
          this.component_items = this.specify_items[index].data
        }
      },
      save_request() {
        this.saving_data = true
        this.alert = false
        var cat = this.category_id
        if (this.$refs.form2.validate()) {
          const data = new FormData()
          data.append('category_id', this.category_id);
          data.append('inventory_category_id', this.data_id);
          data.append('is_minor', this.is_minor);
          data.append('code', this.data_items[this.data_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.data_id)].code);

          var is_car = this.data_items[this.data_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.data_id)].is_car;
          data.append('car_brand', is_car ? this.particulars_id : '');
          data.append('equipment_details', is_car ? '' : this.particulars_id);
          var index = this.specify_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.specify_id);
          if (index != -1) {
            data.append('equipment_code', is_car ? '' : this.specify_items[index].code);
          }
          var index2 = this.component_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.component_id);
          if (index2 != -1) {
            data.append('equipment_components', is_car ? '' : this.component_items[index2].particulars);
          }
          this.register_company_inventory(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                var color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false

              Object.assign(this.$data, initialState())
              this.initialize_data()

            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
    },
  }
</script>
